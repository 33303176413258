import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {tap} from 'rxjs/internal/operators/tap';
import {User, USER_ROLE} from '../models/user';
import {Store} from '@ngrx/store';
import {selectAuthState, UserState} from '../store/user/auth.state';
import {catchError, first, map} from 'rxjs/operators';
import {JWT_REFRESH_TOKEN_STORAGE, JWT_TOKEN_STORAGE, USER_DATA} from '../../../environments/config';
import {ToastHandlerService} from './toast-handler.service';
import {TranslateService} from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private httpWithoutInterceptor: HttpClient;
  private refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE);
  private accessToken = localStorage.getItem(JWT_TOKEN_STORAGE);

  constructor(private http: HttpClient,
              private httpBackend: HttpBackend,
              private router: Router,
              private toastService: ToastHandlerService,
              private translate: TranslateService,
              private store: Store<UserState>) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  public logIn(email: string, password: string, rememberMe: boolean): Observable<any> {
    return this.http.post(`${environment.backendUrl}auth/app/login`,
      {email, password, remember_me: rememberMe}
    );
  }

  public loginAs(userId: string) {
    return this.http.post(`${environment.backendUrl}auth/login-as`, {
      userId
    }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public loginWithToken(token: string): Observable<any> {
    return this.httpWithoutInterceptor.post(`${environment.backendUrl}auth/login-with-token`, {
      token
    });
  }

  public forgotPassword(email: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}auth/forgot-password`, {
      email
    }).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translate.instant('auth.toast.forgot_password_success')
        );
        return res.data;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translate.instant('auth.toast.forgot_password_error')
        );
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public changePassword(token: string, password: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}auth/change-password`, {
      token,
      password
    }).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translate.instant('auth.toast.change_password_success')
        );
        return res;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translate.instant('auth.toast.change_password_error')
        );
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public changePasswordFromProfile(password: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}auth/change-password-from-profile`, {
      password
    }).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translate.instant('auth.toast.change_password_success')
        );
        return res;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translate.instant('auth.toast.change_password_error')
        );
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public getNewsletters(): Promise<any> {
    return this.http.get(
      `${environment.backendUrl}user/newsletters`
    )
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          return throwError(err);
        })
      ).toPromise();
  }

  public setNewsletterAsRead(newsletterId: string): Promise<any> {
    return this.http.put(
      `${environment.backendUrl}user/newsletters/${newsletterId}`, {
      read: true,
    })
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          return throwError(err);
        })
      ).toPromise();
  }

  /*loginUserByLinkedin(linkedinCode: string): Observable<any> {
    return this.http.post(`${environment.backendUrl}/auth/login/linked-in`, {
      code: linkedinCode
    });
  }

  loginUserByGoogle(googleToken): Observable<any> {
    return this.http.post(`${environment.backendUrl}/auth/login/google`, {
      token: googleToken
    });
  }

  loginUserByFacebook(facebookToken): Observable<any> {
    return this.http.post(`${environment.backendUrl}/auth/login/facebook`, {
      token: facebookToken
    });
  }*/


  signUp(email: string, password: string): Observable<any> {
    return this.http.post(`${environment.backendUrl}/auth/registration`,
      {email, password}
    );
  }

  getUserData() {
    return this.http.get(`${environment.backendUrl}auth/me`);
  }

  updateValetProfile(profileData): Promise<any> {
    return this.http.put(`${environment.backendUrl}valets`, profileData).pipe(
      map((res: any) => {
          this.toastService.showSuccessToast('Profile updated.');
          return res.data;
        },
        catchError(err => {
          this.toastService.showErrorToasts('Problem with profile update.');
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        }))
    ).toPromise();
  }

  refreshTokens() {
    return this.http.post(`${environment.backendUrl}/auth/refresh`, {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((data: any) => {
      console.log('REFRESH');
      console.log(data);
      // localStorage.setItem(JWT_TOKEN_STORAGE, data.accessToken);
      this.updateAccessToken(data.accessToken);
      localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, data.refreshToken);
      this.updateRefreshToken(data.refreshToken);
    }));
  }


  getRefreshToken() {
    return this.refreshToken;
    // return localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE);
  }

  updateRefreshToken(newToken: string) {
    this.refreshToken = newToken;
    const localStorageToken = localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE);
    if (localStorageToken) {
      localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, newToken);
    }
  }

  isAdmin() {
    const user = JSON.parse(localStorage.getItem(USER_DATA));
    if (!user) {
      return null;
    }
    return user.rolesArray.some(role => role === USER_ROLE.ADMIN);
  }

  getAccessToken() {
    return this.accessToken;
    // return localStorage.getItem(JWT_TOKEN_STORAGE);
  }

  updateAccessToken(newToken: string) {
    this.accessToken = newToken;
    const localStorageToken = localStorage.getItem(JWT_TOKEN_STORAGE);
    if (localStorageToken) {
      localStorage.setItem(JWT_TOKEN_STORAGE, newToken);
    }
  }

  setLocalStorageData(accessToken, refreshToken, user: User, remeberMe: boolean) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    if (remeberMe) {
      localStorage.setItem(JWT_TOKEN_STORAGE, accessToken);
      localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, refreshToken);
      if (user) {
        localStorage.setItem(USER_DATA, JSON.stringify(user));
      }
    }
  }

  setUserLocalStorageData(user: User) {
    localStorage.setItem(USER_DATA, JSON.stringify(user));
    console.log(user)
  }

  logOutUser() {
    localStorage.removeItem(JWT_TOKEN_STORAGE);
    localStorage.removeItem(JWT_REFRESH_TOKEN_STORAGE);
    localStorage.removeItem(USER_DATA);
  }

  public hasRoleCheck(role: USER_ROLE | USER_ROLE[]): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      first(),
      map(userData => {
        const roles = Array.isArray(role) ? role : [role];
        if (userData.user && roles.includes(userData.user.role)) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public getRole(): Observable<USER_ROLE> {
    return this.store.select(selectAuthState).pipe(
      first(),
      map(userData => {
        if (userData.user && userData.user.role) {
          return userData.user.role;
        } else {
          return null;
        }
      })
    );
  }

  public getUser(): Observable<any> {
    return this.store.select(selectAuthState).pipe(
      first(),
      map(userData => {
        if (userData.user) {
          return userData.user;
        } else {
          return null;
        }
      })
    );
  }

  public isAccessTokenExpired() {
    const token = localStorage.getItem(JWT_TOKEN_STORAGE);
    if (token) {
      const decodedToken: any = jwtDecode(token);
      return decodedToken.exp <= Date.now() / 1000;
    }
    return true;
  }

  public getDefaultUrlFromRole(role: USER_ROLE) {
    switch (role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.QUALITY:
        return '/admin';
      case USER_ROLE.CUSTOMER_ADMIN:
      case USER_ROLE.CUSTOMER_USER:
      case USER_ROLE.CUSTOMER_READ_ONLY:
        return '/customer';
      case USER_ROLE.CANDIDATE:
        return '/recruitment-process';
      case USER_ROLE.VALET:
        return '/client/evaluation';
      default:
        return null;
    }
  }
}


